<template>
  <div>
    <div v-if="showOrDontShow === true">
      <header class="main-header">
        <div class="row row-page-top">
          <h1 class="poster-rail--title section-title">Control parental</h1>
        </div>
      </header>

      <main class="wrapper container-fluid control-parental" role="main">
        <section class="row featured-content">
          <ul class="nav nav-tabs" role="tablist">
            <li class="nav-item">
              <a
                v-on:click.prevent="showTab('pin')"
                class="nav-link"
                :class="{ active: tab == 'pin' }"
                data-toggle="tab"
                href="#pin"
                role="tab"
              >
                Código PIN
              </a>
            </li>

            <li class="nav-item">
              <a
                v-on:click.prevent="showTab('channels')"
                class="nav-link"
                :class="{ active: tab == 'channels' }"
                data-toggle="tab"
                href="#channels"
                role="tab"
              >
                Bloqueo de canales
              </a>
            </li>
          </ul>

          <div class="tab-content">
            <div
              class="tab-pane tab-pane--pin fade"
              :class="{ 'show active': tab == 'pin' }"
              role="tabpanel"
            >
              <form
                v-on:submit.prevent="onSubmit()"
                class="form parental-control"
                role="form"
                novalidate
              >
                <div class="form-row">
                  <div class="form-group col-sm-12 col-md-6">
                    <label for="ageDefinition">Definir edades</label>

                    <select
                      class="custom-select"
                      @change="samChange($event)"
                      id="selectSAM"
                    >
                      <option value="0" :selected="sam == 0">ATP</option>
                      <option value="7" :selected="sam == 7">SAM7</option>
                      <option value="13" :selected="sam == 13">SAM13</option>
                      <option value="16" :selected="sam == 16">SAM16</option>
                      <option value="18" :selected="sam == 18">SAM18</option>
                      <option value="-1" :selected="sam == '-1'">
                        DESACTIVADO
                      </option>
                    </select>
                  </div>
                </div>

                <div class="form-row">
                  <div class="form-group col-sm-12 col-md-6">
                    <label for="pinNumber">Tiempo para recordar el PIN</label>

                    <select class="custom-select" id="selectRemember">
                      <option value="15" :selected="remember == 15">
                        15 minutos
                      </option>
                      <option value="60" :selected="remember == 60">
                        1 hora
                      </option>
                      <option value="0" :selected="remember == 0">
                        Hasta salir del sitio
                      </option>
                      <option value="-1" :selected="remember == -1">
                        Nunca
                      </option>
                    </select>
                  </div>
                </div>

                <div class="form-row form-row--action">
                  <button type="submit" class="btn btn-primary">
                    Guardar cambios
                  </button>

                  <button
                    v-on:click="changePin()"
                    type="button"
                    class="btn btn-secondary"
                  >
                    Cambiar código
                  </button>
                </div>
              </form>
            </div>

            <div
              class="tab-pane tab-pane--channels fade"
              :class="{ 'show active': tab == 'channels' }"
              role="tabpanel"
            >
              <p>
                Seleccioná los canales que quieras bloquear en la lista de
                abajo.
              </p>
              <p>
                Una vez bloqueados para poder verlos vas a necesitar el código
                PIN generado anteriormente...
              </p>

              <template v-if="channels.length > 0">
                <template>
                  <div class="row">
                    <div
                      v-for="(item, index) of channels"
                      :key="item.idChannel"
                      class="channel-item"
                      :style="{ cursor: canBlock ? 'pointer' : 'wait' }"
                      :class="{ blocked: isBlocked(item.idChannel) }"
                      v-on:click="
                        changeBlocked(item.idChannel);
                        sendGrafanaEventEnter('block-channel-' + item.name);
                      "
                    >
                      <img
                        :src="item.logoCanal"
                        :alt="item.name"
                        class="channel-logo"
                      />
                      <i
                        v-if="isBlocked(item.idChannel)"
                        class="zmdi zmdi-lock"
                      ></i>
                    </div>
                  </div>
                </template>
              </template>
            </div>
          </div>
        </section>
      </main>
    </div>
  </div>
</template>

<script>
export default {
  name: "parentalControl",

  data() {
    return {
      sam: "-1",
      remember: "-1",
      channels: [],
      blockedChannels: [],
      tab: "pin",
      canBlock: true,
      showOrDontShow: false,
    };
  },

  mounted() {
    let self = this;
    self.$bus.$on("modal-pin:mounted", function () {
      self.init();
    });
    self.init();
  },

  beforeDestroy() {
    this.$bus.$off("modal-pin:mounted");
  },

  methods: {
    samChange(event) {
      this.sam = event.target.value;
      this.sendGrafanaEventEnter(this.sam);
    },
    init() {
      let self = this;

      this.$bus.$emit(
        "modal-pin:show",
        function () {
          self.sam = telecentro.tplay.core.biz.parentalControl.getAge();
          self.remember =
            telecentro.tplay.core.biz.parentalControl.getRemember();

          telecentro.tplay.core.listChannel.obtenerCanales(function (ret) {
            self.channels = ret;
          });

          telecentro.tplay.core.biz.parentalControl.getBlockedChannels(
            function (ret) {
              self.blockedChannels = ret;
            },
            function (ret) {
              self.$bus.$emit(
                "show-toast",
                "error",
                "No se pudo obtener la lista de canales bloqueados."
              );
            }
          );
          self.showOrDontShow = true;
        },
        function () {
          self.$router.push("/inicio");
        }
      );
    },

    showTab(tab) {
      this.sendGrafanaEvent(tab);
      if (tab === "channels" || tab === "pin") {
        this.tab = tab;
      } else {
        this.tab = "pin";
      }
    },

    setAge() {
      let value = document.getElementById("selectSAM").value;

      this.sendGrafanaEventEnter(value);

      if (value == "-1") {
        telecentro.tplay.core.biz.parentalControl.setAge(false, 0);
      } else {
        telecentro.tplay.core.biz.parentalControl.setAge(true, value);
      }
    },

    setRemember() {
      let value = document.getElementById("selectRemember").value;

      this.sendGrafanaEventEnter(value);

      if (value == "-1") {
        telecentro.tplay.core.biz.parentalControl.setRemember(false, -1);
      } else {
        telecentro.tplay.core.biz.parentalControl.setRemember(true, value);
      }
    },

    onSubmit() {
      this.setRemember();

      this.setAge();

      this.$bus.$emit("show-toast", "success", "Cambios guardados");

      window.location.assign("/inicio");
    },

    changePin() {
      let self = this;

      self.sendGrafanaEventEnter("cambio-pin");

      self.$bus.$emit("modal-pin:showReset", function () {
        self.$bus.$emit(
          "show-toast",
          "success",
          "Código PIN modificado con éxito."
        );
      });
    },

    isBlocked(idChannel) {
      return this.blockedChannels.indexOf(idChannel) === -1 ? false : true;
    },

    changeBlocked(idChannel) {
      if (this.canBlock) {
        this.canBlock = false;
        this.$bus.$emit("show-toast", "success", "Cambios guardados");
        if (telecentro.tplay.core.biz.parentalControl.isBlocked(idChannel)) {
          this.unLockChannels(idChannel);
        } else {
          this.blockedChannel(idChannel);
        }
      }
    },
    blockedChannel(idChannel) {
      let self = this;

      this.blockedChannels.push(idChannel);

      telecentro.tplay.core.biz.parentalControl.setBlockedChannels(
        this.blockedChannels,
        function () {
          self.isBlocked(idChannel);
          self.canBlock = true;
        },
        function (ret) {
          self.$bus.$emit(
            "show-toast",
            "error",
            "No se pudo obtener la lista de canales bloqueados."
          );
          self.canBlock = true;
        }
      );
    },

    unLockChannels(idChannel) {
      let self = this;

      var i = this.blockedChannels.indexOf(idChannel);

      if (i !== -1) {
        this.blockedChannels.splice(i, 1);

        telecentro.tplay.core.biz.parentalControl.setBlockedChannels(
          this.blockedChannels,
          function () {
            self.isBlocked(idChannel);
            self.canBlock = true;
          },
          function (ret) {
            self.$bus.$emit(
              "show-toast",
              "error",
              "No se pudo obtener la lista de canales bloqueados."
            );
            self.canBlock = true;
          }
        );
      }
    },
    sendGrafanaEvent(name) {
      const self = this;
      tplay.sendGrafanaEvent(
        tplay.grafana.event.BUTTON,
        { name: name, screen: self.$route.name },
        tplay.grafana.actions.ENTER
      );
    },

    sendGrafanaEventEnter(name) {
      const self = this;
      tplay.sendGrafanaEvent(
        tplay.grafana.event.BUTTON,
        { name: name, sub_type: "parental-control", screen: self.$route.name },
        tplay.grafana.actions.ENTER
      );
    },
  },
};
</script>
